import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Block from '../components/Block'
import Accordion from '../components/Accordion'
import Seo from '../components/Seo'
import { Helmet } from 'react-helmet'

const Page = ({ data, pageContext, location }) => (
  <Layout page={data.page} pageContext={pageContext} location={location} cta={data?.wp?.callToAction?.cta}>
    <Helmet>
      <meta name="description" content={data.page?.seo?.metaDesc} />
      <meta property="og:description" content={data.page?.seo?.metaDesc} />
      <meta
        property="og:image"
        content={data.page?.seo?.opengraphImage?.localFile?.publicURL}
      />
    </Helmet>
    <Seo title={data.page?.title} seo={data.page?.seo} />
    <Block
      content={data?.page?.pageData?.flexContent || []}
      latestPosts={data.latestPosts}
      cases={data.cases}
      title={data.page.title}
      pageContext={pageContext}
      id={data.page.id}
      key="content"
    />
    <Accordion
      content={data?.page?.pageDataAccordion?.tab || []}
      latestPosts={data.latestPosts}
      cases={data.cases}
      title={data.page.title}
      pageContext={pageContext}
      id={data.page.id}
      key="content"
    />
  </Layout>
)

export default Page

export const pageQuery = graphql`
  query page($id: String!) {
    latestPosts: allWpPost(
      sort: { order: DESC, fields: date }
      filter: { status: { eq: "publish" } }
      limit: 100
    ) {
      nodes {
        status
        title
        uri
        categories {
          nodes {
            termTaxonomyId
          }
        }
        news {
          description
          ingress
          image {
            altText
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 1920
                  base64Width: 120
                  quality: 85
                  toFormat: JPG
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    cases: allWpCase(
      sort: { order: DESC, fields: date }
      filter: { status: { eq: "publish" } }
    ) {
      nodes {
        id
        title
        content
        status
        caseCategories {
          nodes {
            slug
          }
        }
        case {
          quote {
            name
            text
          }
          image {
            altText
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 1200
                  base64Width: 140
                  quality: 85
                  toFormat: JPG
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    page: wpPage(id: { eq: $id }) {
      pageTemplate
      id
      databaseId
      title
      uri
      slug
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        readingTime
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          id
          mediaDetails {
            width
            height
          }
          localFile {
            publicURL
          }
        }
        breadcrumbs {
          text
          url
        }
        schema {
          articleType
          pageType
          raw
        }
      }
      pageData {
        __typename
        flexContent {
          __typename
          ... on WpPage_Pagedata_FlexContent_Top {
            fieldGroupName
            cssClass
            position
            text
            title
            linkColor
            link {
              target
              url
              title
            }
            mp4 {
              localFile {
                publicURL
              }
            }
            webm {
              localFile {
                publicURL
              }
            }
            videoPoster {
              localFile {
                publicURL
              }
            }
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 1920
                    base64Width: 500
                    quality: 85
                    toFormat: JPG
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageMobile {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 800
                    base64Width: 240
                    quality: 85
                    toFormat: JPG
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Content {
            fieldGroupName
            title
            text
            columns
          }
          ... on WpPage_Pagedata_FlexContent_Offices {
            fieldGroupName
            title
            offices {
              title
              text
              imageLink
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 1920
                      base64Width: 120
                      quality: 85
                      toFormat: JPG
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Contact {
            fieldGroupName
            title
            people {
              name
              text
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 800
                      base64Width: 120
                      quality: 85
                      toFormat: JPG
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Timeline {
            fieldGroupName
          }
          ... on WpPage_Pagedata_FlexContent_Industries {
            fieldGroupName
            rubrik
            textTop
            industry {
              text
            }
            text
          }
          ... on WpPage_Pagedata_FlexContent_BgText {
            fieldGroupName
            text
            title
            position
            linkColor
            link {
              target
              title
              url
            }
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 1920
                    base64Width: 120
                    quality: 85
                    toFormat: JPG
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageMobile {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 800
                    base64Width: 240
                    quality: 85
                    toFormat: JPG
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Circle {
            fieldGroupName
            bgColor
            text
            title
            position
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 1200
                    base64Width: 80
                    quality: 85
                    toFormat: JPG
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Images {
            fieldGroupName
            bgColor
            position
            blocks {
              text
              title
              imageType
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 1200
                      base64Width: 80
                      quality: 85
                      toFormat: JPG
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Triple {
            fieldGroupName
            title
            blocks {
              text
              title
              link {
                target
                title
                url
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 1200
                      base64Width: 80
                      quality: 85
                      toFormat: JPG
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Double {
            fieldGroupName
            title
            blocks {
              text
              title
              link {
                target
                title
                url
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 1200
                      base64Width: 80
                      quality: 85
                      toFormat: JPG
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Quadruple {
            fieldGroupName
            title
            blocks {
              text
              title
              link {
                target
                title
                url
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 1200
                      base64Width: 80
                      quality: 85
                      toFormat: JPG
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Sustainable {
            fieldGroupName
            text
            title
            image1 {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 1200
                    base64Width: 80
                    quality: 85
                    toFormat: JPG
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            image2 {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 1200
                    base64Width: 80
                    quality: 85
                    toFormat: JPG
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Cta {
            fieldGroupName
          }
          ... on WpPage_Pagedata_FlexContent_News {
            fieldGroupName
            title
            filter {
              termTaxonomyId
            }
          }
          ... on WpPage_Pagedata_FlexContent_Case {
            fieldGroupName
            title
            category {
              slug
            }
          }
          ... on WpPage_Pagedata_FlexContent_Video {
            fieldGroupName
            bg {
              localFile {
                publicURL
              }
            }
            mp4 {
              localFile {
                publicURL
              }
            }
            webm {
              localFile {
                publicURL
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Form {
            fieldGroupName
            position
            typ
            title
            text
            textBottom
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 1920
                    base64Width: 240
                    quality: 85
                    toFormat: JPG
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageMobile {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 800
                    base64Width: 60
                    quality: 85
                    toFormat: JPG
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      pageDataAccordion{
        __typename
        tab{
          tabtext
          fieldGroupName
          flexContent{
            ... on WpPage_Pagedataaccordion_tab_FlexContent_Content {
              fieldGroupName
              title
              text
              columns
            }
            ... on WpPage_Pagedataaccordion_tab_FlexContent_Video {
              fieldGroupName
              bg {
                localFile {
                  publicURL
                }
              }
              mp4 {
                localFile {
                  publicURL
                }
              }
              webm {
                localFile {
                  publicURL
                }
              }
            }
            ... on WpPage_Pagedataaccordion_tab_FlexContent_Offices {
              fieldGroupName
              title
              offices {
                title
                text
                imageLink
                image {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(
                        maxWidth: 1920
                        base64Width: 120
                        quality: 85
                        toFormat: JPG
                      ) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
            ... on WpPage_Pagedataaccordion_tab_FlexContent_Contact {
              fieldGroupName
              title
              people {
                name
                text
                image {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(
                        maxWidth: 800
                        base64Width: 120
                        quality: 85
                        toFormat: JPG
                      ) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
            ... on WpPage_Pagedataaccordion_tab_FlexContent_Industries {
              fieldGroupName
              rubrik
              textTop
              industry {
                text
              }
              text
            }
            ... on WpPage_Pagedataaccordion_tab_FlexContent_BgText {
              fieldGroupName
              text
              title
              position
              linkColor
              link {
                target
                title
                url
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 1920
                      base64Width: 120
                      quality: 85
                      toFormat: JPG
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              imageMobile {
                altText
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 800
                      base64Width: 240
                      quality: 85
                      toFormat: JPG
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            ... on WpPage_Pagedataaccordion_tab_FlexContent_Circle {
              fieldGroupName
              bgColor
              text
              title
              position
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 1200
                      base64Width: 80
                      quality: 85
                      toFormat: JPG
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            ... on WpPage_Pagedataaccordion_tab_FlexContent_Images {
              fieldGroupName
              bgColor
              position
              blocks {
                text
                title
                imageType
                image {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(
                        maxWidth: 1200
                        base64Width: 80
                        quality: 85
                        toFormat: JPG
                      ) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
            ... on WpPage_Pagedataaccordion_tab_FlexContent_Triple {
              fieldGroupName
              title
              blocks {
                text
                title
                link {
                  target
                  title
                  url
                }
                image {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(
                        maxWidth: 1200
                        base64Width: 80
                        quality: 85
                        toFormat: JPG
                      ) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
            ... on WpPage_Pagedataaccordion_tab_FlexContent_Double {
              fieldGroupName
              title
              blocks {
                text
                title
                link {
                  target
                  title
                  url
                }
                image {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(
                        maxWidth: 1200
                        base64Width: 80
                        quality: 85
                        toFormat: JPG
                      ) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
            ... on WpPage_Pagedataaccordion_tab_FlexContent_Quadruple {
              fieldGroupName
              title
              blocks {
                text
                title
                link {
                  target
                  title
                  url
                }
                image {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(
                        maxWidth: 1200
                        base64Width: 80
                        quality: 85
                        toFormat: JPG
                      ) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
            ... on WpPage_Pagedataaccordion_tab_FlexContent_Sustainable {
              fieldGroupName
              text
              title
              image1 {
                altText
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 1200
                      base64Width: 80
                      quality: 85
                      toFormat: JPG
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              image2 {
                altText
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 1200
                      base64Width: 80
                      quality: 85
                      toFormat: JPG
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            ... on WpPage_Pagedataaccordion_tab_FlexContent_News {
              fieldGroupName
              title
              filter {
                termTaxonomyId
              }
            }
            ... on WpPage_Pagedataaccordion_tab_FlexContent_Case {
              fieldGroupName
              title
              category {
                slug
              }
            }
            ... on WpPage_Pagedataaccordion_tab_FlexContent_Video {
              fieldGroupName
              bg {
                localFile {
                  publicURL
                }
              }
              mp4 {
                localFile {
                  publicURL
                }
              }
              webm {
                localFile {
                  publicURL
                }
              }
            }
            ... on WpPage_Pagedataaccordion_tab_FlexContent_Form {
              fieldGroupName
              position
              typ
              title
              text
              textBottom
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 1920
                      base64Width: 240
                      quality: 85
                      toFormat: JPG
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              imageMobile {
                altText
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 800
                      base64Width: 60
                      quality: 85
                      toFormat: JPG
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    wp {
      callToAction {
        cta {
          btnShow
          btnAltText
          btnSvg {
            localFile {
              publicURL
            }
          }
          btnLink {
            target
            title
            url
          }
          btnFilter {
            ... on WpPage {
              databaseId
            }
          }
          form {
            title
            textBottom
            text
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 1920
                    base64Width: 240
                    quality: 85
                    toFormat: JPG
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageMobile {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 800
                    base64Width: 60
                    quality: 85
                    toFormat: JPG
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
